import React from 'react'
import { cn } from '@/lib/utils'
import { DivProps, HeadingProps, ParagraphProps, RenderParsedProps } from './types'

/**
 * H1 Component
 *
 * A component for rendering an <h1> element.
 *
 * @param {string} props.className - default: banner-title text-blue-prussian
 * @param {string} props.children - The content to display within the <h1> element.
 */
export function H1({ className, children, ...props }: HeadingProps) {
  return (
    <>
      <h1 className={cn('text-blue-prussian banner-title', className)} {...props}>
        {children}
      </h1>
    </>
  )
}

/**
 * H2 Component
 *
 * A component for rendering an <h2> element.
 *
 * @param {string} props.className - default: section-title text-black-pearl
 * @param {string} props.children - The content to display within the <h2> element.
 */
export function H2({ className, children, ...props }: HeadingProps) {
  return (
    <>
      <h2 className={cn('text-black-pearl section-title', className)} {...props}>
        {children}
      </h2>
    </>
  )
}

/**
 * H3 Component
 *
 * A component for rendering an <h3> element.
 *
 * @param {string} props.className - default: text-blue-cyan section-body-title--large
 * @param {string} props.children - The content to display within the <h3> element.
 * @param {boolean} props.isLarge - is true className will be section-body-title
 */
export function H3({ className, children, ...props }: HeadingProps) {
  return (
    <>
      <h3
        className={cn('text-blue-cyan section-body-title', { 'section-body-title--large': props?.isLarge }, className)}
        {...props}
      >
        {children}
      </h3>
    </>
  )
}

/**
 * H4 Component
 *
 * A component for rendering an <h4> element.
 *
 * @param {string} props.className - default: text-blue-cyan
 * @param {string} props.children - The content to display within the <h4> element.
 */
export function H4({ className, children, ...props }: HeadingProps) {
  return (
    <>
      <h4 className={cn('text-blue-cyan section-body-title', className)} {...props}>
        {children}
      </h4>
    </>
  )
}

/**
 * H5 Component
 *
 * A component for rendering an <h5> element.
 *
 * @param {string} props.className - default: text-blue-cyan
 * @param {string} props.children - The content to display within the <h5> element.
 */
export function H5({ className, children, ...props }: HeadingProps) {
  return (
    <>
      <h5 className={cn('text-blue-cyan', className)} {...props}>
        {children}
      </h5>
    </>
  )
}

/**
 * H6 Component
 *
 * A component for rendering an <h6> element.
 *
 * @param {string} props.className - default: text-blue-cyan
 * @param {string} props.children - The content to display within the <h6> element.
 */
export function H6({ className, children, ...props }: HeadingProps) {
  return (
    <>
      <h6 className={cn('text-blue-cyan', className)} {...props}>
        {children}
      </h6>
    </>
  )
}

/**
 * P Component
 *
 * A component for rendering an <p> element.
 *
 * @param {string} props.className - default: text-twilight body-content
 * @param {string} props.children - The content to display within the <p> element.
 */
export function P({ className, children, ...props }: ParagraphProps) {
  return (
    <p className={cn('text-twilight body-content', className)} {...props}>
      {children}
    </p>
  )
}

/**
 * Paragraph Component
 *
 * A component for rendering an <p> element.
 *
 * @param {string} props.className - default: text-twilight body-content
 * @param {string} props.children - The content to display within the <p> element.
 */
export function Paragraph({ className, children, ...props }: ParagraphProps) {
  return (
    <p className={cn('text-twilight body-content', className)} {...props}>
      {children}
    </p>
  )
}

/**
 * SectionBox Component
 *
 * A component for rendering an <div> element.
 *
 * @param {string} props.className - default: text-twilight body-content.
 * @param {string} props.children - The content to display within the <div> element.
 */
export function SectionBox({ className, children, ...props }: DivProps) {
  return (
    <div className={cn('text-twilight body-content', className)} {...props}>
      {children}
    </div>
  )
}

/**
 * RenderParsed Component
 *
 * A component for rendering an HTML element with parser.
 *
 * @param {string} props.className - default: text-twilight body-content.
 * @param {string | ParsedContent} props.children - The content to display within the HTML element.
 */
export function RenderParsedContent({ className, children, tag, ...props }: RenderParsedProps) {
  const strOrHtml = typeof children === 'string' ? children : children.html
  const classes = typeof children === 'string' ? '' : children.classes

  const Tag = tag ?? (typeof children === 'string' ? Paragraph : SectionBox)

  return (
    <Tag className={cn('space-y-4 text-twilight body-content', className, classes)} {...props}>
      {strOrHtml}
    </Tag>
  )
}
