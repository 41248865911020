import Head from 'next/head'
import { useRouter } from 'next/router'

import CONFIGS from '@/configs'

interface Props {
  title: string
  favIcon?: string
  keywords: string
  description: string
}

/**
 * Must Call this component in the top of every page or component.
 * Pass title, keywords, description, link whatever you want to change.
 * If you call this component and don't pass any value as props, it will show the defaults.
 * For Sequence Read Me: guidelines/META.md
 */

const Meta = ({
  keywords = '',
  description = '',
  title = CONFIGS.appName,
  favIcon = '/assets/fav/favicon.ico'
}: Props) => {
  const titleData = title ?? ``
  const descriptionData = description ?? ``

  const router = useRouter()
  const asPath = router.asPath === '/' ? '' : router.asPath
  const canonicalUrl = `${CONFIGS.appUrl}${asPath}`

  return (
    <>
      <Head>
        <meta name='description' content={`${descriptionData}`} />
        <meta name='keywords' content={`${keywords}`} />
        <meta name='author' content={CONFIGS.appName} />

        {/*FB META Tags*/}

        <meta property='og:title' content={`${titleData}-${CONFIGS.appName}`} />
        <meta property='og:description' content={descriptionData} />
        <meta property='og:image' content={`${CONFIGS.appUrl}/paytome-social-media.jpg`} />
        <meta property='og:url' content={CONFIGS.appUrl} />
        <meta property='og:site_name' content={CONFIGS.appName} />

        {/*Twitter META Tags*/}

        <meta name='twitter:title' content={`${titleData}-${CONFIGS.appName}`} />
        <meta name='twitter:description' content={descriptionData} />
        <meta name='twitter:image' content={`${CONFIGS.appUrl}/paytome-social-media.jpg`} />
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:image:alt' content={CONFIGS.appName} />
        <meta name='twitter:site' content={`@${CONFIGS.appName}`} />

        <link rel='icon' href={`${favIcon}`} />
        <link rel='canonical' href={canonicalUrl} />
        <link rel='manifest' href='/assets/fav/site.webmanifest' />
        <link rel='manifest' href='/assets/fav/site.webmanifest' />
        <link rel='apple-touch-icon' sizes='180x180' href='/assets/fav/apple-touch-icon.png' />
        <link rel='icon' type='image/png' sizes='32x32' href='/assets/fav/favicon-32x32.png' />
        <link rel='icon' type='image/png' sizes='16x16' href='/assets/fav/favicon-16x16.png' />

        <title>{`${titleData}: ${CONFIGS.appName}`}</title>
      </Head>
    </>
  )
}

export default Meta
